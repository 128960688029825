.priceCalculator {
    text-align: center;
}

.priceCalculator .priceLevel {
    padding-bottom: 8px;
    padding-top: 8px;
    font-size: x-large;
    text-align: center;
    border-bottom: 1px solid lightgray;
}

.priceCalculator .priceLevel-info {
    font-size: large;
}

.priceCalculator input {
    width: 100px;
}

.priceCalculator label {
    width: 100px;
}
