.btn {
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Helvetica Neue", Arial, sans-serif;
}

h1 {
    font-size: 42px;
    font-weight: 300;
}

h2 {
    font-size: 36px;
    font-weight: 300;
}

h3 {
    font-size: 32px;
}

h3, h3 > small {
    font-weight: 300;
}

h4 {
    font-size: 25px;
    font-weight: 300;
}

h5 {
    font-size: 20px;
    font-weight: 300;
}

h6 {
    font-size: 16px;
    font-weight: 300;
}

h1 .bold, h2 .bold, h3 .bold, h4 .bold, h5 .bold, h6 .bold {
    font-weight: 600;
}

.dl-horizontal dt {
    font-weight: 500;
}

.markdown-header {
    font-size: 0.7em;
}

/* Allow links without hrefs to still look like links -- this is nice when we are handling routing with
onClick */
a, a:not([href]):not([tabindex]) {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover, a:not([href]):not([tabindex]):hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
}

.videoInsert {
    width: 100%    !important;
    height: auto   !important;
}