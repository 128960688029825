.itemPreviewSquare {
    margin-top: 10px;
    margin-bottom: 10px;

    height: 280px;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    color: black;
    padding: 5px;
}

/* TODO: figure out how to webpack SASS/LESS so that I can include this as a nested */
.itemPreviewSquare:hover {
    color: inherit;
    text-decoration: none;
    border: 10px #eee solid;
    border-radius: 5px;
}
.itemPreviewSquare-imgContainer {
    width: 100%;
    text-align: center;
}
.itemPreviewSquare-img {
    max-height: 160px;
}
.itemPreviewSquare-textContainer {
    width: 100%;
    text-align: center;
    line-height: 1.25;
}
.itemPreviewSquare:hover .stock {
    bottom: 0 !important;
}
#dimDiv{
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 8;
    background-color: rgba(255, 255, 255, 0.7);
}
#dimButton{
    cursor: pointer;
    padding: 20px;
    position: fixed;
    display: none;
    border: none;
    -webkit-appearance: none;
    background: none;
    right: 0;
    top: 20px;
    z-index: 10;
}
.big-image {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
}