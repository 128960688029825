.publicHeader {
    margin-bottom: 30px;
}
.logo-image {
    max-height: 42px;
}
.nav-link {
    white-space: nowrap;
}
.nav-custom-background {
    background-color: #7f7f7f!important;
}
