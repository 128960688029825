.footer {
    border-top: 1px solid #dddddd;
    padding: 20px 0 30px;
    background-color: #eeeeee;
    margin-top: 20px;
    color: #333333;
    font-size: 14px;
}

.footer a {
    color: #777;
}

.footer li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.footer a:hover {
    color: #777;
}

.footer-header {
    color: #5c7c83;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 20px 0 10px;
    text-transform: uppercase;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-socialLinks li {
    display: inline-block;
    margin-right: 15px;
}