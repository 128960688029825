.searchItems-tableCell {
    padding: 10px;
    border-top: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}
div.ReactVirtualized__Table__rowColumn {
    white-space: normal;
}
.ReactVirtualized__Table__row {
    border-top: solid 1px darkgray;
}
.ReactVirtualized__Table__headerRow {
    margin: auto;
}